.home_container {
height: 100%;
position: relative;
}

.home-title-container {
background-color: var(--selected-primary-button-color);
height: 60px;
display: flex;
align-items: center;
}
.home-title-text {
color: var(--sign-in-text-color);
font-size: 18px;
margin-bottom: 0;
padding: 0 35px;
}
.home-title-text-underline {
text-decoration: underline;
}
.tabs-container {
padding: 25px 35px;
height: 100%;
background-color: var(--background-color);
min-height: 100vh;
}
.tabs-button {
height: 46px;
flex: 1;
background-color: var(--selected-primary-button-color);
outline: none;
font-weight: 700;
color: #a68792;
}

/* .query-result-pagination{
position: absolute;
bottom: 20;
padding: 10px 0px;
right: 35px;
} */
.actives {
background-color: var(--secondary-color) !important;
color: white !important;
}
.registration_input {
width: 100%;
background-color: #fff;
padding: 30px 12px;
border: 1px solid var(--border-color);
color: grey;
border-radius: 1px;
font-size: 16px;
height: 40px;
text-decoration: none;
outline: 0;
}

.tab-active {
color: white;
background-color: var(--secondary-color) !important;
}
.summary-container {
display: flex;
align-items: center;
padding: 20px;
background: var(--sign-in-text-color);
border-bottom: 1px solid #ddd;
margin-bottom: 20px;
}
.summary-watchlist {
flex-shrink: 0;
}
.summary-watchlist-container {
flex-grow: 1;
}
.summary-items-wrapper {
display: flex;
justify-content: space-between;
align-items: center;
gap: 10px;
}
.summary-student-container {
display: flex;
align-items: center;
padding: 10px;
cursor: pointer;
/* border-right: 1px solid var(--border-color); */
flex: 1;
}
.no-goal-badge {
background: #ff6b00;
color: white;
padding: 2px 8px;
border-radius: 4px;
font-size: 11px;
font-weight: 500;
text-transform: uppercase;
}
.summary-student-container:last-child {
border-right: none;
}
.summary-text-container {
padding-left: 10px;
}
.summary-student-text {
margin: 0;
font-size: 14px;
font-weight: 600;
white-space: nowrap;
}
.summary-allstudent-image {
width: 40px;
height: 40px;
object-fit: contain;
}
.sepratorr {
border-left: 1px solid black;
}
.student-progress-container {
padding: 20px 35px;
display: flex;
justify-content: space-between;
align-items: center;
width: 100%;
}
.student-progress-container h6 {
color: var(--selected-primary-button-color);
font-size: 18px;
font-weight: 700;
padding: 10px 0 0 30px;
}
.search-student-modify{
width: 30%;
max-width: 400px;
margin-left: 10px;
display: flex;
align-items: center;
}
.search-student{
    width: 35%;
    display: flex;
    align-items: center;
}
.student-list-container {
margin: 15px auto;
width: 95%;
display: flex;
flex-wrap: wrap;
border-bottom: 1px solid #ddd;
border-top: 1px solid #ddd;
padding-left: 30px;
padding-right: 0px;
}
.success-border {
border-left: 8px solid var(--success-color);
border-right: 8px solid var(--success-color);
}
.atrisk-border {
border-left: 8px solid var(--yellow-color);
border-right: 8px solid var(--yellow-color);
}
.offtrack-border {
border-left: 8px solid var(--at-risk-color);
border-right: 8px solid var(--at-risk-color);
}
.offredtrack-border {
border-left: 8px solid var(--at-red-color);
border-right: 8px solid var(--at-red-color);
}
.student-avatar {
border-radius: 50%;
width: 60px;
height: 60px;
}
.student-name {
font-size: 17px;
font-weight: 700;
color: var(--title-text-color);
margin-bottom: 0;
padding: 5px 0 0 30px;
cursor: pointer;
}
.student-list-icon {
width: 30px;
height: 30px;
}
.student-container {
display: grid;
grid-template-columns: repeat(2, 1fr);
}
.grade-container {
margin-left: 60px;
}
.student-msg-container {
padding: 0;
}
.listContainer {
margin: 0px 15px;
padding-bottom: 50px;
}
.counselor-studentlist-tabs-container {
display: grid;
grid-template-columns: repeat(3, 1fr);
gap: 5px;
cursor: pointer;
}
.counselor-studentlist-tabs {
height: 46px;
text-align: center;
line-height: 46px;
}
.inactive {
background: var(--selected-primary-button-color);
color: var(--tab-button-color);
height: 46px;
font-weight: bold;
}
@media screen and (max-width: 1024px) {
.summary-student-container {
padding: 0px;
}
.home-title-text {
padding: 0 15px;
}
.student-list-container {
padding-left: 0;
padding-right: 15px;
}

.name-and-grade-seprator {
display: none;
}
.summary-container {
padding: 10px 0;
}
.home-title-container {
height: auto;
padding: 10px;
}
.summary-watchlist {
width: 100%;
padding-bottom: 15px;
}
.summary-watchlist-container {
display: grid;
grid-template-columns: repeat(2, 1fr);
grid-gap: 15px;
}
/* .search-student {
width: 100%;
} */
.student-progress-container {
flex-wrap: wrap;
}
.grade-container {
margin-left: 0px;
}
.student-name-container {
justify-content: center;
}
.student-msg-container {
padding: 10px 0;
width: 100%;
}
.tabs-container {
padding: 25px 10px;
}
.student-name {
padding: 5px 0 0 5px;
width: min-content;
}
.counselor-name-image-container {
width: 100%;
}
.counselor-goal-status {
width: 100%;
}
.counselor-student-container {
/* position: absolute; */
bottom: 0px;
}
.listContainer {
margin: 0px;
}
.summary-allstudent-image {
width: 60px;
height: 40px;
padding-left: 10px;
}
}

/* Update the search and select styling */
.student-progress-container {
padding: 20px 35px;
display: flex;
justify-content: space-between;
align-items: center;
width: 100%;
}

.student-text {
font-size: 16px;
font-weight: 600;
color: #333;
margin: 0;
text-transform: uppercase;
}

.search-controls {
display: flex;
align-items: center;
gap: 15px;
}

/* .search-student {
display: flex;
align-items: center;
} */

.registration_input {
height: 45px;
border: 1px solid #8e8e8e;
border-radius: 4px;
padding: 8px 12px;
font-size: 14px;
color: #666;
}

/* Search input specific styles */
/* .search-student .registration_input {
width: 250px;
border-right: none;
border-top-right-radius: 0;
border-bottom-right-radius: 0;
} */

.search-icon-container {
background-color: #3b1a2c;
width: 45px;
height: 45px;
display: flex;
align-items: center;
justify-content: center;
border-top-right-radius: 4px;
border-bottom-right-radius: 4px;
}

/* Select input specific styles */
.select-container {
width: 250px;
}

.select-goal {
width: 100%;
height: 45px;
background-color: #fff;
border: 1px solid var(--border-color);
color: #333; /* Darker color for better visibility */
font-size: 14px;
padding: 0 12px;
appearance: none;
-webkit-appearance: none;
-moz-appearance: none;
background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
background-repeat: no-repeat;
background-position: right 12px center;
background-size: 16px;
}

.select-goal option {
color: #333;
padding: 8px;
}

/* Style for placeholder option */
.select-goal option[value=""] {
color: #666;
}

/* Remove default focus outline and add custom one */
.select-goal:focus {
outline: none;
border-color: var(--secondary-color);
box-shadow: 0 0 0 2px rgba(59, 26, 44, 0.1);
}

/* Hover state */
.select-goal:hover {
border-color: var(--secondary-color);
}

@media screen and (max-width: 768px) {
.student-progress-container {
flex-direction: column;
align-items: flex-start;
}

.search-controls {
flex-direction: column;
width: 100%;
margin-top: 15px;
gap: 10px;
}

/* .search-student,
.select-container {
width: 100%;
} */

/* .search-student .registration_input {
width: 100%;
} */
}

select.registration_input {
color: #666;
}

/* Style for the placeholder option */
select.registration_input option[value=""][disabled] {
color: #999;
}

/* Style for when no option is selected */
select.registration_input:invalid {
color: #999;
}

/* Responsive styles */
@media screen and (max-width: 1200px) {
.summary-items-wrapper {
flex-wrap: wrap;
}

.summary-student-container {
flex: 1 1 calc(20% - 10px);
min-width: 150px;
}
}

@media screen and (max-width: 768px) {
.summary-container {
flex-direction: column;
}

.summary-watchlist {
margin-bottom: 15px;
}

.summary-student-container {
flex: 1 1 calc(50% - 10px);
}
}

.name-degree-container {
display: flex;
flex-direction: column;
padding-left: 30px;
}

.student-name {
padding: 0;
margin: 0;
}

.student-degree {
margin-top: 4px;
}
